import React from 'react';
import { Button } from '@chakra-ui/react';
import { Session } from './types';

const Logout: React.FC<{ session: Session }> = ({ session }) => {
  return (
    <Button type="button" variant="ghost" onClick={session.logout}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill="#fff"
          d="M16.484 17.563a.745.745 0 00.54.223c.204 0 .39-.074.539-.223l5.023-5.023a.493.493 0 00.093-.112s.019-.019.019-.037c.018-.037.037-.075.056-.093 0-.019 0-.037.018-.037.019-.038.019-.056.038-.093.018-.056.018-.094.018-.15 0-.055 0-.092-.018-.148a.142.142 0 00-.038-.093c0-.019 0-.037-.018-.037l-.056-.112s0-.019-.019-.019c-.018-.037-.056-.074-.093-.111l-5.023-5.024a.763.763 0 00-1.08 1.08l3.722 3.72H6.977a.754.754 0 00-.763.763c0 .428.335.763.763.763h13.228l-3.721 3.721c-.298.26-.298.744 0 1.042z"
        />
        <path
          fill="#fff"
          d="M1.954 22.79H12a.754.754 0 00.763-.762V14.53a.754.754 0 00-.763-.763.754.754 0 00-.763.763v6.735h-8.52V2.716h8.52v6.735c0 .428.335.763.763.763a.754.754 0 00.763-.763V1.953A.754.754 0 0012 1.191H1.954a.754.754 0 00-.763.762v20.075c.019.428.353.763.763.763z"
        />
      </svg>
    </Button>
  );
};

export default Logout;
