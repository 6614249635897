'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require('@slate-editor/utils');

var _EmbedUtils = require('./EmbedUtils');

var EmbedKeyboardShortcut = function EmbedKeyboardShortcut(event, change) {
  if (_utils.keyboardEvent.isMod(event) && event.key === 'e') return (0, _EmbedUtils.appendEmbed)(change);
  return;
};

exports.default = EmbedKeyboardShortcut;