import React from "react";

const Icon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#000"
        d="M18 30.375c-6.823 0-12.375-5.552-12.375-12.375S11.177 5.625 18 5.625 30.375 11.177 30.375 18 24.823 30.375 18 30.375zm0-22.5C12.417 7.875 7.875 12.417 7.875 18S12.417 28.125 18 28.125 28.125 23.583 28.125 18 23.583 7.875 18 7.875z"
      ></path>
      <path
        fill="#000"
        d="M18.003 30.375c-3.709 0-6.505-5.32-6.505-12.375s2.796-12.375 6.505-12.375c3.71 0 6.506 5.32 6.506 12.375s-2.796 12.375-6.506 12.375zm0-22.5c-2.012 0-4.255 4.158-4.255 10.125s2.243 10.125 4.255 10.125c2.014 0 4.256-4.158 4.256-10.125S20.017 7.875 18.003 7.875z"
      ></path>
      <path
        fill="#000"
        d="M18 15.795c-3.832 0-7.464-.88-9.964-2.416a1.123 1.123 0 01-.37-1.547 1.124 1.124 0 011.549-.37c2.155 1.324 5.356 2.083 8.785 2.083 3.43 0 6.63-.759 8.786-2.084a1.125 1.125 0 111.177 1.919c-2.499 1.534-6.13 2.415-9.963 2.415zM27.375 24.795c-.201 0-.405-.054-.588-.167-2.157-1.323-5.36-2.083-8.787-2.083-3.428 0-6.63.759-8.785 2.083a1.124 1.124 0 11-1.178-1.918c2.499-1.535 6.132-2.415 9.963-2.415 3.831 0 7.464.88 9.965 2.416.529.326.694 1.019.37 1.548a1.128 1.128 0 01-.96.536zM32.25 7.5a3.726 3.726 0 01-2.652-1.098A3.726 3.726 0 0128.5 3.75c0-1.002.39-1.944 1.098-2.652 1.415-1.416 3.888-1.416 5.303 0C35.61 1.806 36 2.748 36 3.75c0 1.002-.39 1.943-1.098 2.652A3.729 3.729 0 0132.25 7.5zm0-5.25c-.4 0-.777.156-1.06.44a1.49 1.49 0 00-.44 1.06c0 .4.156.777.44 1.06.566.568 1.553.568 2.12 0 .284-.283.44-.66.44-1.06s-.156-.777-.44-1.06a1.49 1.49 0 00-1.06-.44z"
      ></path>
      <path
        fill="#000"
        d="M25.955 11.175a1.125 1.125 0 01-.795-1.922l4.185-4.185a1.125 1.125 0 111.591 1.592l-4.185 4.185c-.22.22-.508.33-.796.33zM5.61 31.515a1.125 1.125 0 01-.795-1.922l4.441-4.44a1.125 1.125 0 011.591 1.592l-4.44 4.44c-.22.22-.508.33-.796.33z"
      ></path>
      <path
        fill="#000"
        d="M3.75 36a3.726 3.726 0 01-2.652-1.098A3.726 3.726 0 010 32.25c0-1.002.39-1.944 1.098-2.652 1.415-1.416 3.888-1.416 5.303 0 .709.708 1.099 1.65 1.099 2.652 0 1.002-.39 1.943-1.098 2.652A3.729 3.729 0 013.75 36zm0-5.25c-.4 0-.777.156-1.06.44a1.49 1.49 0 00-.44 1.06c0 .4.156.777.44 1.06.567.567 1.554.567 2.12 0 .284-.283.44-.66.44-1.06s-.156-.777-.44-1.06a1.49 1.49 0 00-1.06-.44zM32.25 36a3.729 3.729 0 01-2.652-1.098A3.729 3.729 0 0128.5 32.25c0-1.002.39-1.944 1.098-2.652 1.415-1.416 3.888-1.416 5.303 0 .709.708 1.099 1.65 1.099 2.652 0 1.002-.39 1.944-1.098 2.652A3.726 3.726 0 0132.25 36zm0-5.25c-.4 0-.777.156-1.06.44a1.49 1.49 0 00-.44 1.06c0 .4.156.777.44 1.06.566.567 1.553.567 2.12 0 .284-.283.44-.66.44-1.06s-.156-.777-.44-1.06a1.49 1.49 0 00-1.06-.44z"
      ></path>
      <path
        fill="#000"
        d="M30.14 31.26c-.288 0-.576-.11-.795-.33l-4.185-4.185a1.125 1.125 0 111.591-1.591l4.185 4.184a1.125 1.125 0 01-.796 1.922zM10.051 11.175c-.288 0-.576-.11-.795-.33l-4.44-4.44a1.125 1.125 0 111.591-1.591l4.44 4.44a1.125 1.125 0 01-.796 1.921z"
      ></path>
      <path
        fill="#000"
        d="M3.75 7.5a3.729 3.729 0 01-2.652-1.098A3.729 3.729 0 010 3.75c0-1.002.39-1.944 1.098-2.652 1.415-1.416 3.888-1.416 5.303 0C7.11 1.806 7.5 2.748 7.5 3.75c0 1.002-.39 1.944-1.098 2.652A3.726 3.726 0 013.75 7.5zm0-5.25c-.4 0-.777.156-1.06.44a1.49 1.49 0 00-.44 1.06c0 .4.156.777.44 1.06.567.568 1.554.568 2.12 0 .284-.283.44-.66.44-1.06s-.156-.777-.44-1.06a1.49 1.49 0 00-1.06-.44z"
      ></path>
    </svg>
  );
}

Icon.displayName = 'PropagateDomainIcon';

export default Icon;
