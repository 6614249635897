import React from "react";

const Icon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="109"
      height="78"
      fill="none"
      viewBox="0 0 109 78"
    >
      <path
        fill="#50E3C2"
        d="M54.111 72.787c20.193 0 36.563-16.294 36.563-36.394C90.674 16.293 74.304 0 54.11 0S17.55 16.294 17.55 36.393c0 20.1 16.37 36.394 36.562 36.394z"
        opacity="0.297"
      ></path>
      <path
        fill="#50E3C2"
        d="M54.111 63.714c16.155 0 29.25-13.035 29.25-29.115S70.266 5.484 54.111 5.484c-16.154 0-29.25 13.035-29.25 29.115s13.096 29.115 29.25 29.115z"
        opacity="0.3"
      ></path>
      <path
        fill="#50E3C2"
        d="M54.111 56.469c12.116 0 21.938-9.776 21.938-21.836 0-12.06-9.822-21.836-21.938-21.836-12.115 0-21.937 9.776-21.937 21.836 0 12.06 9.822 21.836 21.937 21.836z"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M27.04 66.083l4.423-7.397c1.103-1.845.354-4.14-1.674-5.128l-.044-.021a4.468 4.468 0 00-2.417-.423l.315-5.502c.112-1.958-1.53-3.63-3.669-3.732-1.599-.077-3.022.745-3.685 1.983-.623-1.047-1.807-1.782-3.203-1.849-1.818-.087-3.41.987-3.914 2.516a4.051 4.051 0 00-2.388-.905c-2.139-.103-3.963 1.402-4.075 3.36l-.027.465a4.057 4.057 0 00-2.347-.872C2.196 48.476.372 49.98.26 51.938l-.202 3.534v.015l-.026.028S0 57.323 0 57.592c0 .269.073 1.478.22 2.42.044.276.294 1.344.486 2.121.408 1.65 2.125 4.548 3.966 7.028l.158.23c.072.106.137.233.196.376l.546 5.614c.072.738.786 1.336 1.596 1.336h15.121c.81 0 1.525-.6 1.596-1.337l.42-4.312c.153-.627.398-1.27.86-1.845M68.745 48.369l5.264-8.804c1.313-2.195.42-4.928-1.993-6.103a5.368 5.368 0 00-2.928-.529l.375-6.547c.133-2.332-1.822-4.32-4.367-4.443-1.903-.09-3.597.887-4.386 2.36-.742-1.245-2.151-2.12-3.813-2.2-2.163-.104-4.057 1.175-4.658 2.994a4.822 4.822 0 00-2.842-1.077c-2.545-.122-4.716 1.669-4.85 4l-.031.552a4.828 4.828 0 00-2.794-1.037c-2.545-.122-4.717 1.669-4.85 3.999l-.24 4.206c-.031.051-.07 2.203-.07 2.523 0 .32.088 1.76.263 2.88.051.329.35 1.6.578 2.525.485 1.963 2.529 5.413 4.72 8.364l.188.273c.086.127.164.278.234.448l.83 22.912c.086.878.936 1.59 1.9 1.59h17.997c.964 0 1.814-.713 1.9-1.591 0 0 .06-18.72.317-21.363.182-.745.475-1.51 1.024-2.196M103.822 66.083l4.423-7.397c1.103-1.845.353-4.14-1.675-5.128l-.043-.021a4.47 4.47 0 00-2.417-.423l.314-5.502c.112-1.958-1.53-3.63-3.668-3.732-1.6-.077-3.022.745-3.686 1.983-.623-1.047-1.807-1.782-3.203-1.849-1.818-.087-3.41.987-3.914 2.516a4.05 4.05 0 00-2.388-.905c-2.138-.103-3.963 1.402-4.075 3.36l-.026.465a4.057 4.057 0 00-2.348-.872c-2.138-.102-3.963 1.402-4.075 3.36l-.202 3.534v.015l-.025.028s-.033 1.808-.033 2.077c0 .269.074 1.478.22 2.42.044.276.294 1.344.486 2.121.408 1.65 2.125 4.548 3.966 7.028l.158.23c.072.106.138.233.197.376l.545 5.614c.072.738.786 1.336 1.596 1.336h15.122c.81 0 1.524-.6 1.596-1.337l.419-4.312c.153-.627.399-1.27.86-1.845"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

Icon.displayName = 'Targets';

export default Icon;
