export default {
  baseStyle: {
    dialog: {
      borderRadius: 'none',
    },
    header: {
      fontSize: '3xl',
      fontWeight: 'black',
    },
  },
  defaultProps: {
    isCentered: true,
  },
};
