import React from 'react';

const Icon = ({ className }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className + ' fill'}
    fill="none"
    viewBox="0 0 25 18"
  >
    <path
      fill="#000"
      d="M24.3 17.858c0-3.508-2.845-6.362-6.342-6.362h-4.116c-3.497 0-6.342 2.854-6.342 6.362h1.625c0-2.61 2.116-4.732 4.717-4.732h4.116c2.601 0 4.717 2.123 4.717 4.732H24.3z"
    />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M15.906 1.542a3.758 3.758 0 00-3.752 3.763 3.758 3.758 0 003.752 3.764 3.758 3.758 0 003.751-3.764 3.758 3.758 0 00-3.751-3.763zm-5.29 3.763c0-2.93 2.369-5.305 5.29-5.305 2.92 0 5.289 2.375 5.289 5.305 0 2.93-2.369 5.306-5.29 5.306-2.92 0-5.288-2.376-5.288-5.306z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      d="M9.815 3.421a4.236 4.236 0 00-2.119-.563c-2.327 0-4.213 1.862-4.213 4.159 0 2.297 1.886 4.16 4.213 4.16 1.34 0 2.532-.617 3.304-1.579a4.702 4.702 0 01-.884-.849 2.997 2.997 0 01-2.42 1.218c-1.65 0-2.989-1.32-2.989-2.95 0-1.63 1.338-2.95 2.99-2.95.592 0 1.144.17 1.609.464a4.59 4.59 0 01.51-1.11zM9.538 11.875a5.092 5.092 0 00-.207-.005h-3.28C3.267 11.87 1 14.108 1 16.858h1.295c0-2.046 1.685-3.71 3.757-3.71h1.74a5.612 5.612 0 011.746-1.273z"
    />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M7.696 2.958c-2.273 0-4.113 1.818-4.113 4.059 0 2.24 1.84 4.06 4.113 4.06 1.27 0 2.406-.57 3.16-1.462a4.808 4.808 0 01-.738-.705 3.098 3.098 0 01-2.422 1.158c-1.705 0-3.089-1.365-3.089-3.051 0-1.686 1.384-3.05 3.09-3.05.565 0 1.096.15 1.552.413a4.69 4.69 0 01.426-.922 4.137 4.137 0 00-1.979-.5zM3.383 7.017c0-2.353 1.932-4.26 4.313-4.26.79 0 1.532.21 2.169.578l.09.052-.055.088c-.212.335-.38.699-.499 1.085l-.038.125-.11-.07a2.903 2.903 0 00-1.557-.449c-1.597 0-2.889 1.278-2.889 2.851s1.292 2.85 2.89 2.85c.961 0 1.813-.464 2.338-1.177l.078-.105.081.102a4.6 4.6 0 00.865.83l.084.062-.065.082a4.326 4.326 0 01-3.382 1.615c-2.38 0-4.313-1.906-4.313-4.259zM.9 16.857c0-2.806 2.312-5.087 5.152-5.087H9.33c.07 0 .141.002.211.005l.039.19a5.511 5.511 0 00-1.715 1.251l-.03.032H6.052c-2.018 0-3.657 1.62-3.657 3.61v.1H.9v-.1zm.201-.1h1.095c.054-2.055 1.764-3.709 3.856-3.709h1.676c.39-.422.842-.786 1.344-1.078h-3.02c-2.698 0-4.897 2.14-4.95 4.788z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      d="M24.3 17.858c0-3.508-2.845-6.362-6.342-6.362h-4.116c-3.497 0-6.342 2.854-6.342 6.362h1.625c0-2.61 2.116-4.732 4.717-4.732h4.116c2.601 0 4.717 2.123 4.717 4.732H24.3z"
    />
    <path
      fill="#000"
      d="M9.815 3.421a4.236 4.236 0 00-2.119-.563c-2.327 0-4.213 1.862-4.213 4.159 0 2.297 1.886 4.16 4.213 4.16 1.34 0 2.532-.617 3.304-1.579a4.702 4.702 0 01-.884-.849 2.997 2.997 0 01-2.42 1.218c-1.65 0-2.989-1.32-2.989-2.95 0-1.63 1.338-2.95 2.989-2.95.593 0 1.145.17 1.61.464a4.59 4.59 0 01.51-1.11zM9.538 11.875a5.093 5.093 0 00-.207-.005h-3.28C3.267 11.87 1 14.108 1 16.858h1.295c0-2.046 1.685-3.71 3.757-3.71h1.74a5.612 5.612 0 011.746-1.273z"
    />
  </svg>
);

Icon.displayName = 'Icon.Network';

export default Icon;
