import React from 'react';

const Icon = ({ className }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="30"
    fill="none"
    className={className + ' fill'}
    viewBox="0 0 23 30"
  >
    <path
      fill="#000"
      d="M22.31 11.729c-.198-.296-.573-.479-.979-.479H13.57L14.59 1.016c.043-.429-.27-.827-.756-.965-.486-.14-1.026.016-1.305.377L.18 16.366a.807.807 0 00-.043.957c.196.302.575.49.986.49h7.769L7.862 28.99c-.04.433.28.831.774.965.114.03.23.045.346.045.382 0 .75-.164.958-.449l12.35-16.874a.807.807 0 00.02-.948zM10.459 25.246l.765-8.299a.85.85 0 00-.296-.71 1.238 1.238 0 00-.824-.3H3.187l8.794-11.35-.75 7.523c-.026.261.08.52.293.713.212.192.511.302.826.302h6.98l-8.871 12.12z"
    ></path>
  </svg>
);

Icon.displayName = 'Icon.BoltUnfilled';

export default Icon;
