import React from 'react';

const Icon = ({ className }: any) => (
  <svg
    className={className + ' fill'}
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8001 18C16.8001 14.492 13.9551 11.6382 10.458 11.6382H6.34162C2.84498 11.6382 0 14.492 0 18H1.62509C1.62509 15.3908 3.74095 13.2684 6.34162 13.2684H10.458C13.0592 13.2684 15.175 15.3908 15.175 18H16.8001Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.40559 1.68385C6.33344 1.68385 4.65364 3.36885 4.65364 5.44741C4.65364 7.52596 6.33344 9.21097 8.40559 9.21097C10.4777 9.21097 12.1575 7.52596 12.1575 5.44741C12.1575 3.36885 10.4777 1.68385 8.40559 1.68385ZM3.1167 5.44741C3.1167 2.51739 5.48462 0.142151 8.40559 0.142151C11.3266 0.142151 13.6945 2.51739 13.6945 5.44741C13.6945 8.37742 11.3266 10.7527 8.40559 10.7527C5.48462 10.7527 3.1167 8.37742 3.1167 5.44741Z"
      fill="black"
    />
  </svg>
);

Icon.displayName = 'Icon.User';

export default Icon;
