'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _components = require('@slate-editor/components');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// FIXME: Needs to handle assets files to work with SSR
// eslint-disable-next-line @typescript-eslint/no-var-requires
/* eslint-disable no-undef */
if (require('exenv').canUseDOM) require('./ImageEditLayer.module.css');

// eslint-disable-next-line react/prop-types
var ImageEditLayer = function ImageEditLayer(_ref) {
  var changeModalState = _ref.changeModalState,
      text = _ref.text;

  return changeModalState ? _react2.default.createElement(
    'div',
    { className: 'image-node--image-edit-layer', onClick: function onClick() {
        return changeModalState(true);
      } },
    _react2.default.createElement(
      _components.Button,
      { className: 'image-node--image-edit-button', onClick: function onClick() {
          return changeModalState(true);
        } },
      text
    )
  ) : _react2.default.createElement(
    'div',
    { className: 'image-node--image-edit-layer', style: { backgroundColor: 'rgba(255,255,255,.9)' } },
    _react2.default.createElement(
      'p',
      { className: 'image-node--image-edit-text' },
      text
    )
  );
};

exports.default = ImageEditLayer;