'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.renderMark = exports.renderNode = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _slateReact = require('slate-react');

var _alignmentPlugin = require('@slate-editor/alignment-plugin');

var _embedPlugin = require('@slate-editor/embed-plugin');

var _gridPlugin = require('@slate-editor/grid-plugin');

var _imagePlugin = require('@slate-editor/image-plugin');

var _linkPlugin = require('@slate-editor/link-plugin');

var _listPlugin = require('@slate-editor/list-plugin');

var _boldPlugin = require('@slate-editor/bold-plugin');

var _colorPlugin = require('@slate-editor/color-plugin');

var _fontFamilyPlugin = require('@slate-editor/font-family-plugin');

var _fontSizePlugin = require('@slate-editor/font-size-plugin');

var _italicPlugin = require('@slate-editor/italic-plugin');

var _strikethroughPlugin = require('@slate-editor/strikethrough-plugin');

var _underlinePlugin = require('@slate-editor/underline-plugin');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; } /* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */


//
// Nodes
//


//
// Marks
//


/* eslint-disable default-case */
var renderNode = exports.renderNode = function renderNode(props) {
  // eslint-disable-next-line react/prop-types
  switch (props.node.type) {
    case 'alignment':
      return _react2.default.createElement(_alignmentPlugin.AlignmentNode, props);
    case 'embed':
      return _react2.default.createElement(_embedPlugin.EmbedNode, props);
    case 'grid':
      return _react2.default.createElement(_gridPlugin.GridNode, props);
    case 'grid-row':
      return _react2.default.createElement(_gridPlugin.GridRowNode, props);
    case 'grid-cell':
      return _react2.default.createElement(_gridPlugin.GridCellNode, props);
    case 'image':
      return _react2.default.createElement(_imagePlugin.ImageNode, props);
    case 'imageLink':
      return _react2.default.createElement(_imagePlugin.ImageLinkNode, props);
    case 'link':
      return _react2.default.createElement(_linkPlugin.LinkNode, props);
    case 'list-item':
      return _react2.default.createElement(_listPlugin.ListItemNode, props);
    case 'ordered-list':
      return _react2.default.createElement(_listPlugin.OrderedListNode, props);
    case 'unordered-list':
      return _react2.default.createElement(_listPlugin.UnorderedListNode, props);
  }
};

var renderMark = exports.renderMark = function renderMark(props) {
  // eslint-disable-next-line react/prop-types
  switch (props.mark.type) {
    case 'bold':
      return _react2.default.createElement(_boldPlugin.BoldMark, props);
    case 'color':
      return _react2.default.createElement(_colorPlugin.ColorMark, props);
    case 'font-family':
      return _react2.default.createElement(_fontFamilyPlugin.FontFamilyMark, props);
    case 'font-size':
      return _react2.default.createElement(_fontSizePlugin.FontSizeMark, props);
    case 'italic':
      return _react2.default.createElement(_italicPlugin.ItalicMark, props);
    case 'strikethrough':
      return _react2.default.createElement(_strikethroughPlugin.StrikethroughMark, props);
    case 'underline':
      return _react2.default.createElement(_underlinePlugin.UnderlineMark, props);
  }
};
/* eslint-disable default-case */

// eslint-disable-next-line react/display-name

exports.default = function (_ref) {
  var className = _ref.className,
      wrapperStyle = _ref.wrapperStyle,
      style = _ref.style,
      value = _ref.value,
      outerState = _ref.outerState,
      plugins = _ref.plugins,
      onChange = _ref.onChange,
      children = _ref.children,
      rest = _objectWithoutProperties(_ref, ['className', 'wrapperStyle', 'style', 'value', 'outerState', 'plugins', 'onChange', 'children']);

  // eslint-disable-next-line react/prop-types
  var readOnly = outerState.readOnly;


  return _react2.default.createElement(
    'div',
    { className: (0, _classnames2.default)('editor--content', className), style: wrapperStyle },
    _react2.default.createElement(_slateReact.Editor, _extends({
      plugins: plugins,
      value: value,
      onChange: onChange,
      readOnly: readOnly,
      outerState: outerState,
      style: style,
      renderNode: renderNode,
      renderMark: renderMark
    }, rest)),
    children
  );
};