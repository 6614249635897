import React, { forwardRef } from "react";
import { Box } from "bonde-components/chakra";

type IconWrapperProps = {
  children?: React.ReactNode;
};

const IconInfo = forwardRef<HTMLDivElement, IconWrapperProps>(({ children, ...rest }, ref) => (
  <Box ref={ref} {...rest}>
     <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.00603 14C10.8648 14 14 10.8621 14 7C14 3.13793 10.8648 0 7.00603 0C3.14729 0 0 3.13793 0 7C0 10.8621 3.14729 14 7.00603 14ZM7.00603 0.965517C10.3221 0.965517 13.0233 3.66897 13.0233 6.98793C13.0233 10.3069 10.3221 13.0103 7.00603 13.0103C3.68992 13.0103 0.988803 10.3069 0.988803 6.98793C0.988803 3.66897 3.68992 0.965517 7.00603 0.965517Z" fill="#AAAAAA"/>
    <path d="M7.10821 10.7037C6.92366 10.7037 6.76987 10.6524 6.64684 10.5497C6.53064 10.4403 6.47254 10.2864 6.47254 10.088V6.12702C6.47254 5.92863 6.53064 5.77813 6.64684 5.67552C6.76987 5.5729 6.92366 5.52159 7.10821 5.52159C7.29276 5.52159 7.44655 5.5729 7.56958 5.67552C7.69261 5.77813 7.75413 5.92863 7.75413 6.12702V10.088C7.75413 10.2864 7.69261 10.4403 7.56958 10.5497C7.44655 10.6524 7.29276 10.7037 7.10821 10.7037ZM7.10821 4.60832C6.87581 4.60832 6.69127 4.54675 6.55456 4.42361C6.41786 4.29363 6.34951 4.12603 6.34951 3.92079C6.34951 3.71556 6.41786 3.55138 6.55456 3.42824C6.69127 3.3051 6.87581 3.24353 7.10821 3.24353C7.33377 3.24353 7.5149 3.3051 7.6516 3.42824C7.79514 3.55138 7.86691 3.71556 7.86691 3.92079C7.86691 4.12603 7.79856 4.29363 7.66185 4.42361C7.52515 4.54675 7.3406 4.60832 7.10821 4.60832Z" fill="#AAAAAA"/>
    </svg>
    {children}
  </Box>
))

const IconOpen = forwardRef<HTMLDivElement, IconWrapperProps>(({ children, ...rest }, ref) => (
  <Box ref={ref} {...rest}>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_4634_5529)">
      <path d="M7.41125 1.15329C7.28465 1.08588 7.14343 1.05062 7 1.05062C6.85657 1.05062 6.71535 1.08588 6.58875 1.15329L1.33875 3.95329C1.19877 4.02783 1.08168 4.13901 0.999994 4.27494C0.91831 4.41087 0.875106 4.56645 0.875 4.72504V5.43992L5.90625 8.45867L7 7.80242L8.09375 8.45867L13.125 5.43992V4.72504C13.1249 4.56645 13.0817 4.41087 13 4.27494C12.9183 4.13901 12.8012 4.02783 12.6613 3.95329L7.41125 1.15329ZM13.125 6.46017L8.94425 8.96879L13.125 11.4774V6.46017ZM13.0944 12.4802L7 8.82179L0.905625 12.4793C0.955959 12.6647 1.06591 12.8284 1.21851 12.9451C1.37112 13.0618 1.55789 13.125 1.75 13.125H12.25C12.4421 13.125 12.6289 13.0618 12.7815 12.9451C12.9341 12.8284 13.044 12.6647 13.0944 12.4793V12.4802ZM0.875 11.4765L5.05575 8.96792L0.875 6.46017V11.4774V11.4765ZM6.17663 0.380666C6.43004 0.245544 6.71281 0.174866 7 0.174866C7.28719 0.174866 7.56996 0.245544 7.82337 0.380666L13.0734 3.18067C13.3533 3.32993 13.5874 3.55247 13.7506 3.8245C13.9138 4.09653 14 4.4078 14 4.72504V12.25C14 12.7142 13.8156 13.1593 13.4874 13.4875C13.1592 13.8157 12.7141 14 12.25 14H1.75C1.28587 14 0.840752 13.8157 0.512563 13.4875C0.184374 13.1593 8.75341e-09 12.7142 8.75341e-09 12.25V4.72504C-3.17158e-05 4.4078 0.0861706 4.09653 0.249387 3.8245C0.412604 3.55247 0.646696 3.32993 0.926625 3.18067L6.17663 0.380666Z" fill="#CCCCCC"/>
      </g>
      <defs>
      <clipPath id="clip0_4634_5529">
      <rect width="14" height="14" fill="white"/>
      </clipPath>
      </defs>
    </svg>
    {children}
  </Box>
))

const IconSend = forwardRef<HTMLDivElement, IconWrapperProps>(({ children, ...rest }, ref) => (
  <Box ref={ref} {...rest}>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="send" clip-path="url(#clip0_4634_5522)">
      <path id="Vector" d="M13.8722 0.127744C13.9327 0.188409 13.9741 0.265534 13.9913 0.349536C14.0084 0.433539 14.0004 0.520718 13.9684 0.600244L8.87679 13.3289C8.83191 13.441 8.75699 13.5385 8.66025 13.6108C8.56351 13.683 8.44872 13.7272 8.3285 13.7384C8.20827 13.7496 8.08729 13.7275 7.97886 13.6743C7.87042 13.6212 7.77874 13.5392 7.71391 13.4374L4.93316 9.06674L0.562535 6.28599C0.460438 6.22123 0.378217 6.12952 0.324946 6.02098C0.271674 5.91244 0.249423 5.7913 0.260647 5.67092C0.27187 5.55053 0.316133 5.43559 0.388551 5.33877C0.460968 5.24195 0.558725 5.16702 0.671035 5.12224L13.3997 0.0323687C13.4792 0.000354445 13.5664 -0.00759031 13.6504 0.00952134C13.7344 0.026633 13.8115 0.0680469 13.8722 0.128619V0.127744ZM5.80641 8.81124L8.22228 12.607L12.3637 2.25399L5.80641 8.81124ZM11.745 1.63537L1.39203 5.77674L5.18866 8.19174L11.7459 1.63537H11.745Z" fill="#CCCCCC"/>
      </g>
      <defs>
      <clipPath id="clip0_4634_5522">
      <rect width="14" height="14" fill="white"/>
      </clipPath>
      </defs>
    </svg>
    {children}
  </Box>
))

export { IconInfo, IconOpen, IconSend };
