import React from "react";

const Icon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#000"
        d="M17.252 28.288l-1.168-1.167-1.487 1.488 2.655 2.654 4.146-4.147-1.487-1.487-2.66 2.66z"
      ></path>
      <path
        fill="#000"
        d="M36 15.88a5.769 5.769 0 00-5.216-5.741c-.293-2.557-2.465-4.55-5.092-4.55-.468 0-.928.063-1.369.185C24.148 2.561 21.485 0 18.237 0c-3.361 0-6.096 2.742-6.096 6.111v.3h-.412c-4.19 0-7.598 3.417-7.598 7.617 0 1.542.462 3.03 1.316 4.287a6.366 6.366 0 00-1.195 4.14A4.478 4.478 0 000 26.93c0 2.469 2.004 4.478 4.466 4.478l6.643.002a7.465 7.465 0 0013.789-.017 7.85 7.85 0 004.744-2.253c1.436-1.438 2.227-3.328 2.227-5.322 0-.806-.126-1.582-.358-2.312A5.772 5.772 0 0036 15.88zM18 33.89a5.352 5.352 0 110-10.704 5.352 5.352 0 010 10.704zm10.155-6.243a5.742 5.742 0 01-2.718 1.52A7.463 7.463 0 0018 21.075a7.463 7.463 0 00-7.425 8.225l-6.109-.002a2.369 2.369 0 010-4.738h2.416s-.54-.878-.54-2.506a4.28 4.28 0 014.27-4.282 4.23 4.23 0 011.69.35l1.47.635v-2.885a4.002 4.002 0 013.991-4.003 4.002 4.002 0 013.992 4.003v2.44h2.52c3.028 0 5.49 2.47 5.49 5.505 0 1.43-.571 2.79-1.61 3.83zm2.389-8.122a7.586 7.586 0 00-6.268-3.32h-.417v-.332c0-3.37-2.735-6.112-6.096-6.112-3.32 0-6.03 2.677-6.094 5.99a6.326 6.326 0 00-4.686 1.053 5.508 5.508 0 01-.749-2.775c0-3.037 2.465-5.509 5.495-5.509h2.516V6.111a4.002 4.002 0 013.992-4.002 4.002 4.002 0 013.992 4.002v3.412l1.675-1.237a2.991 2.991 0 011.788-.588 3.029 3.029 0 013.021 3.03c0 .909-.142 1.495-.142 1.495h1.678a3.657 3.657 0 01.295 7.301z"
      ></path>
    </svg>
  );
}

Icon.displayName = 'CertifyDomainIcon';

export default Icon;
