import React from 'react';
import { createIcon } from '@chakra-ui/react';

const InfoIcon = createIcon({
  displayName: 'InfoIcon',
  viewBox: '0 0 14 14',
  path: (
    <>
      <path
        fill="currentColor"
        d="M7.012 14c3.862 0 7-3.138 7-7s-3.138-7-7-7S0 3.138 0 7s3.15 7 7.012 7zm0-13.034a6.032 6.032 0 016.022 6.022 6.032 6.032 0 01-6.022 6.022A6.032 6.032 0 01.99 6.988 6.032 6.032 0 017.012.966z"
      />
      <path
        fill="currentColor"
        d="M8.208 8.824V6.066a.735.735 0 00-.734-.734h-1.35a.735.735 0 00-.734.734v.694c0 .36.262.661.605.722V8.84a.735.735 0 00-.605.723v.694c0 .404.33.734.734.734h1.98c.404 0 .734-.33.734-.734v-.694a.744.744 0 00-.63-.74zm-.17 1.367H6.19v-.563h.12a.485.485 0 00.485-.485V7.18a.485.485 0 00-.485-.485h-.12v-.564h1.22v3c0 .267.217.484.484.484h.145v.576zm-.985-5.098a1.16 1.16 0 100-2.322 1.16 1.16 0 00-.001 2.322zm0-1.523a.363.363 0 11-.001.725.363.363 0 010-.725z"
      />
      <circle cx="6.811" cy="6.811" r="4.865" fill="#fff" />
      <path
        fill="currentColor"
        d="M7.115 10.704a.697.697 0 01-.462-.154c-.116-.11-.174-.264-.174-.462V6.127c0-.198.058-.349.174-.451a.697.697 0 01.462-.154c.185 0 .338.05.462.154.123.102.184.253.184.451v3.961c0 .198-.061.352-.184.462a.697.697 0 01-.462.154zm0-6.096c-.233 0-.417-.061-.554-.184a.663.663 0 01-.206-.503.63.63 0 01.206-.493c.137-.123.321-.184.554-.184.226 0 .407.061.544.184a.616.616 0 01.215.493.663.663 0 01-.205.503c-.137.123-.322.184-.554.184z"
      />
    </>
  ),
});

export default InfoIcon;
