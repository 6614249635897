import React from 'react';

const Icon = ({ className }: any) => (
  <svg
    className={className + ' fill'}
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3575 0C12.6549 0 11.0611 0.726824 10 1.94461C8.93886 0.726782 7.34514 0 5.64255 0C2.53125 0 0 2.38662 0 5.32023C0 7.61737 1.45275 10.2744 4.3178 13.2174C6.52268 15.4823 8.92123 17.2359 9.60382 17.7195L9.99987 18L10.3959 17.7195C11.0785 17.236 13.4771 15.4824 15.6821 13.2175C18.5472 10.2745 20 7.61741 20 5.32023C20 2.38662 17.4687 0 14.3575 0ZM14.7106 12.3767C12.897 14.2396 10.9458 15.7455 9.99987 16.4395C9.05393 15.7455 7.10286 14.2395 5.28927 12.3766C2.69169 9.70836 1.31868 7.26828 1.31868 5.32023C1.31868 3.07225 3.25837 1.24335 5.64255 1.24335C7.21152 1.24335 8.66022 2.04963 9.42334 3.34757L10 4.32841L10.5767 3.34757C11.3397 2.04967 12.7884 1.24335 14.3575 1.24335C16.7416 1.24335 18.6813 3.0722 18.6813 5.32023C18.6813 7.26836 17.3083 9.70844 14.7106 12.3767Z"
      fill="black"
    />
  </svg>
);

Icon.displayName = 'Icon.Heart';

export default Icon;
