import React from "react";

const Icon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#000"
        d="M33.2 2.4H2.8A2.803 2.803 0 000 5.2v25.532c0 1.544 1.256 2.8 2.8 2.8h30.4c1.544 0 2.8-1.256 2.8-2.8V5.2c0-1.544-1.256-2.8-2.8-2.8zm.4 28.331a.4.4 0 01-.4.4H2.8a.4.4 0 01-.4-.4V10.784h31.2v19.949zm0-22.348H2.4V5.2c0-.22.18-.4.4-.4h30.4c.22 0 .4.18.4.4v3.183z"
      ></path>
      <path
        fill="#000"
        d="M5.049 7.494a.903.903 0 100-1.805.903.903 0 000 1.805zM7.946 7.494a.903.903 0 100-1.805.903.903 0 000 1.805zM10.834 7.494a.903.903 0 100-1.805.903.903 0 000 1.805zM5.736 23.481c.312.964 1.608 1.132 2.154.274l.735-1.155.735 1.155c.545.856 1.842.69 2.154-.274l1.397-4.308a1.2 1.2 0 10-2.284-.74l-.61 1.884-.38-.596a1.2 1.2 0 00-2.024 0l-.38.596-.61-1.884a1.2 1.2 0 10-2.283.74l1.396 4.308zM15.111 23.481c.313.965 1.61 1.129 2.154.274L18 22.6l.735 1.155c.546.858 1.842.69 2.154-.274l1.396-4.308a1.2 1.2 0 10-2.283-.74l-.61 1.884-.38-.596a1.2 1.2 0 00-2.024 0l-.38.596-.61-1.884a1.2 1.2 0 10-2.283.74l1.396 4.308zM24.484 23.481c.313.965 1.61 1.129 2.154.274l.735-1.155.735 1.155c.546.858 1.842.69 2.154-.274l1.396-4.308a1.2 1.2 0 10-2.283-.74l-.61 1.884-.38-.596a1.2 1.2 0 00-2.024 0l-.38.596-.61-1.884a1.2 1.2 0 00-2.283.74l1.396 4.308z"
      ></path>
    </svg>
  );
}

Icon.displayName = 'InsertDomainIcon';

export default Icon;
