import React from 'react';

const Icon = ({ className }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className + ' fill'}
    viewBox="0 0 18 14"
    fill="none"
  >
    <path
      d="M15.5581 14C15.5581 11.12 13.2151 8.77704 10.3351 8.77704H6.94517C4.06558 8.77704 1.72266 11.12 1.72266 14H3.06096C3.06096 11.8578 4.80344 10.1153 6.94517 10.1153H10.3351C12.4773 10.1153 14.2198 11.8578 14.2198 14H15.5581Z"
      fill="black"
    />
    <path
      d="M1.94727 6.18966C1.94727 7.29644 2.84795 8.19712 3.95472 8.19712H13.0516C14.1584 8.19712 15.0591 7.29644 15.0591 6.18966V2.00746C15.0591 0.900678 14.1584 0 13.0516 0H3.95472C2.84795 0 1.94727 0.900678 1.94727 2.00746V6.18966ZM3.28557 2.00746C3.28557 1.63853 3.5858 1.3383 3.95472 1.3383H13.0516C13.4206 1.3383 13.7208 1.63853 13.7208 2.00746V6.18966C13.7208 6.55859 13.4206 6.85881 13.0516 6.85881H3.95472C3.5858 6.85881 3.28557 6.55859 3.28557 6.18966V2.00746Z"
      fill="black"
    />
    <path d="M6.81682 2.51956H5.47852V5.67841H6.81682V2.51956Z" fill="black" />
    <path d="M11.7309 2.51956H10.3926V5.67841H11.7309V2.51956Z" fill="black" />
    <path d="M1.33831 2.58249H0V5.61464H1.33831V2.58249Z" fill="black" />
    <path d="M17.2797 2.58249H15.9414V5.61464H17.2797V2.58249Z" fill="black" />
  </svg>
);

Icon.displayName = 'Icon.Bot';

export default Icon;
