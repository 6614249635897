import React from 'react';
import { createIcon } from '@chakra-ui/react';

const NetIcon = createIcon({
  displayName: 'NetIcon',
  path: (
    <>
      <path
        d="M24.3 17.858c0-3.508-2.845-6.362-6.342-6.362h-4.116c-3.497 0-6.342 2.854-6.342 6.362h1.625c0-2.61 2.116-4.732 4.716-4.732h4.117c2.601 0 4.717 2.123 4.717 4.732H24.3z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.905 1.542a3.758 3.758 0 00-3.752 3.763 3.758 3.758 0 003.752 3.764 3.758 3.758 0 003.752-3.764 3.758 3.758 0 00-3.752-3.763zm-5.288 3.763c0-2.93 2.367-5.305 5.288-5.305 2.921 0 5.29 2.375 5.29 5.305 0 2.93-2.369 5.306-5.29 5.306-2.92 0-5.288-2.376-5.288-5.306z"
        fill="currentColor"
      />
      <path
        d="M9.815 3.421a4.236 4.236 0 00-2.119-.563c-2.327 0-4.213 1.862-4.213 4.159 0 2.297 1.886 4.16 4.213 4.16 1.339 0 2.532-.617 3.304-1.579a4.702 4.702 0 01-.884-.849 2.997 2.997 0 01-2.42 1.218c-1.65 0-2.989-1.32-2.989-2.95 0-1.63 1.338-2.95 2.989-2.95.593 0 1.145.17 1.61.464a4.59 4.59 0 01.509-1.11zM9.538 11.875a5.092 5.092 0 00-.207-.005H6.05C3.267 11.87 1 14.108 1 16.858h1.294c0-2.046 1.686-3.71 3.758-3.71h1.74a5.612 5.612 0 011.746-1.273z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.696 2.958c-2.273 0-4.113 1.818-4.113 4.059 0 2.24 1.84 4.06 4.113 4.06 1.27 0 2.406-.57 3.16-1.462a4.803 4.803 0 01-.738-.705 3.098 3.098 0 01-2.422 1.158c-1.705 0-3.089-1.365-3.089-3.051 0-1.686 1.384-3.05 3.089-3.05.566 0 1.097.15 1.553.413a4.69 4.69 0 01.426-.922 4.137 4.137 0 00-1.98-.5zM3.383 7.017c0-2.353 1.932-4.26 4.313-4.26.79 0 1.532.21 2.169.578l.09.052-.055.088c-.212.335-.38.699-.499 1.085l-.038.125-.11-.07a2.903 2.903 0 00-1.557-.449c-1.597 0-2.889 1.278-2.889 2.851s1.292 2.85 2.889 2.85c.962 0 1.814-.464 2.34-1.177l.076-.105.082.102c.25.313.541.592.865.83l.084.062-.065.082a4.326 4.326 0 01-3.382 1.615c-2.381 0-4.313-1.906-4.313-4.259zM.9 16.857c0-2.806 2.312-5.087 5.152-5.087H9.33c.07 0 .141.002.211.005l.038.19a5.511 5.511 0 00-1.714 1.251l-.03.032H6.052c-2.018 0-3.658 1.62-3.658 3.61v.1H.9v-.1zm.2-.1h1.096c.054-2.055 1.764-3.709 3.856-3.709h1.676c.39-.422.842-.786 1.343-1.078h-3.02c-2.697 0-4.896 2.14-4.95 4.788z"
        fill="currentColor"
      />
    </>
  ),
});

export default NetIcon;
