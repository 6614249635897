import React from "react";

const Icon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#000"
        d="M25.82 20.11c5.545 0 10.056-4.51 10.056-10.054C35.876 4.51 31.365 0 25.82 0c-5.206 0-9.5 3.978-10.005 9.053h-3.777v6.803H1.8v13.852H0v2.54A3.756 3.756 0 003.751 36h18.683a3.756 3.756 0 003.751-3.751v-2.54h-1.8v-9.7c.47.066.948.102 1.435.102zm2.288-2.444a14.118 14.118 0 001.413-3.745h3.24a7.984 7.984 0 01-4.653 3.745zm5.463-9.368a7.941 7.941 0 010 3.515h-3.712a17.805 17.805 0 00-.01-3.515h3.722zm-.81-2.109h-3.265c-.323-1.371-.815-2.644-1.458-3.765a7.983 7.983 0 014.723 3.765zm-6.94-3.34c.653.957 1.16 2.09 1.503 3.34h-3.007c.342-1.25.85-2.383 1.503-3.34zm-1.909 5.449h3.817a15.72 15.72 0 01.011 3.515H23.9a15.766 15.766 0 01.012-3.515zm-.309-5.874c-.643 1.12-1.136 2.394-1.458 3.765H18.88a7.983 7.983 0 014.723-3.765zm-.07 15.242a7.984 7.984 0 01-4.654-3.745h3.24c.311 1.36.788 2.626 1.414 3.745zM18.07 8.298h3.722a17.768 17.768 0 00-.01 3.515H18.07a7.946 7.946 0 010-3.515zm-3.923 2.864h1.68a9.998 9.998 0 001.784 4.694h-3.464v-4.694zm-1.055 11.212c.852 0 1.544.693 1.544 1.544 0 .85-.692 1.543-1.543 1.543a1.545 1.545 0 01-1.544-1.543c0-.851.692-1.544 1.543-1.544zm-9.184-4.41h8.13v2.457a3.658 3.658 0 00-2.598 3.497 3.656 3.656 0 003.652 3.652 3.656 3.656 0 003.653-3.652 3.658 3.658 0 00-2.598-3.497v-2.456h5.47a10.053 10.053 0 002.66 1.5v10.243H3.908V17.965zM24.076 32.25c0 .905-.736 1.642-1.642 1.642H3.75A1.645 1.645 0 012.11 32.25v-.432h21.968v.432zm.212-18.328h3.065c-.342 1.292-.86 2.462-1.533 3.445-.672-.983-1.19-2.153-1.532-3.445z"
      ></path>
    </svg>
  );
}

Icon.displayName = 'ConnectDomainIcon';

export default Icon;
