import React from 'react';
import { createIcon } from '@chakra-ui/react';

const BotIcon = createIcon({
  displayName: 'BotIcon',
  path: (
    <>
      <path
        d="M16.319 14.684a5.484 5.484 0 00-5.478-5.478H7.285a5.484 5.484 0 00-5.478 5.478h1.404a4.079 4.079 0 014.074-4.074h3.556a4.079 4.079 0 014.074 4.074h1.404zM2.042 6.492c0 1.161.944 2.106 2.105 2.106h9.542c1.16 0 2.105-.945 2.105-2.106V2.106C15.794.945 14.85 0 13.69 0H4.147c-1.16 0-2.106.945-2.106 2.106v4.386zm1.403-4.386c0-.387.315-.702.702-.702h9.542c.387 0 .701.315.701.702v4.386a.703.703 0 01-.701.702H4.147a.703.703 0 01-.702-.702V2.106z"
        fill="currentColor"
      />
      <path
        d="M7.15 2.643H5.748v3.313H7.15V2.643zM12.304 2.643H10.9v3.313h1.404V2.643zM1.404 2.709H0v3.18h1.404V2.71zM18.125 2.709H16.72v3.18h1.404V2.71z"
        fill="currentColor"
      />
    </>
  ),
});

export default BotIcon;
