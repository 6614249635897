"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _ImageDataModal = require("./ImageDataModal");

var _ImageDataModal2 = _interopRequireDefault(_ImageDataModal);

var _ImageEditLayer = require("./ImageEditLayer");

var _ImageEditLayer2 = _interopRequireDefault(_ImageEditLayer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /* eslint-disable react/prop-types */


var ImageLinkNode = function (_Component) {
  _inherits(ImageLinkNode, _Component);

  function ImageLinkNode(props) {
    _classCallCheck(this, ImageLinkNode);

    var _this = _possibleConstructorReturn(this, (ImageLinkNode.__proto__ || Object.getPrototypeOf(ImageLinkNode)).call(this, props));

    _this.state = { isModalActive: false };
    return _this;
  }

  _createClass(ImageLinkNode, [{
    key: "modal",
    value: function modal(isModalActive) {
      this.setState({ isModalActive: isModalActive });
    }
  }, {
    key: "render",
    value: function render() {
      var isModalActive = this.state.isModalActive;
      var _props = this.props,
          node = _props.node,
          attributes = _props.attributes,
          readOnly = _props.readOnly,
          isSelected = _props.isSelected,
          _props$editor = _props.editor,
          onChange = _props$editor.onChange,
          value = _props$editor.props.value;


      return _react2.default.createElement(
        "span",
        null,
        !isModalActive ? null : _react2.default.createElement(_ImageDataModal2.default, {
          node: node,
          value: value,
          onChange: onChange,
          changeModalState: this.modal.bind(this)
        }),
        _react2.default.createElement(
          "div",
          {
            className: (0, _classnames2.default)("image-node--container", {
              readonly: readOnly
            })
          },
          this.props.children,
          isSelected && _react2.default.createElement(_ImageEditLayer2.default, {
            changeModalState: this.modal.bind(this),
            text: "Editar"
          }),
          !readOnly && !isSelected && _react2.default.createElement(_ImageEditLayer2.default, { text: "Selecione a imagem para editar" }),
          _react2.default.createElement(
            "a",
            {
              href: node.data.get("href"),
              target: node.data.get("openExternal") ? "_blank" : "_self"
            },
            "REACT_APP_DOMAIN_IMAGINARY" in process.env && !node.data.get("src").match(/gif$/i) ? _react2.default.createElement("img", _extends({}, attributes, {
              role: "presentation",
              loading: "lazy",
              className: "image-node " + (!readOnly && isSelected && "selected"),
              src: process.env.REACT_APP_DOMAIN_IMAGINARY + "/convert?url=" + node.data.get("src") + "&type=png",
              title: node.data.get("title"),
              alt: node.data.get("title")
            })) : _react2.default.createElement("img", _extends({}, attributes, {
              role: "presentation",
              loading: "lazy",
              className: "image-node " + (!readOnly && isSelected && "selected"),
              src: node.data.get("src"),
              title: node.data.get("title"),
              alt: node.data.get("title")
            }))
          )
        )
      );
    }
  }]);

  return ImageLinkNode;
}(_react.Component);

exports.default = ImageLinkNode;