import React from 'react';

const Icon = ({ className, color }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 18 22"
  >
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-153-28h1440v130H-153z" />
      <path
        fill="#FFF"
        d="M16.632 15.045c0 3.478-2.88 5.749-6.48 5.749H1.8V1.135h7.848c3.456 0 6.336 2.2 6.336 5.607a4.852 4.852 0 0 1-1.728 3.832 5.238 5.238 0 0 1 2.376 4.471zM6.336 5.323V8.8h3.24c.475.013.934-.172 1.263-.51.329-.338.498-.797.465-1.264a1.682 1.682 0 0 0-.478-1.253 1.733 1.733 0 0 0-1.25-.521h-3.24v.07zm5.76 9.367a1.795 1.795 0 0 0-.51-1.362 1.85 1.85 0 0 0-1.362-.554H6.336v3.761h3.816a1.773 1.773 0 0 0 1.41-.473c.376-.357.571-.86.534-1.372z"
      />
      <path
        fill="#FFF"
        d="M16.272 14.832c0 3.478-2.88 5.749-6.48 5.749H1.44V.923h7.848c3.456 0 6.336 2.2 6.336 5.606a4.852 4.852 0 0 1-1.728 3.832 5.238 5.238 0 0 1 2.376 4.471zM5.976 5.11v3.477h3.24c.475.013.934-.172 1.263-.51.329-.338.498-.797.465-1.264a1.647 1.647 0 0 0-.465-1.264 1.697 1.697 0 0 0-1.263-.51l-3.24.07zm5.76 9.367a1.795 1.795 0 0 0-.51-1.362 1.85 1.85 0 0 0-1.362-.554H5.976v3.762h3.816a1.773 1.773 0 0 0 1.41-.474c.376-.357.571-.86.534-1.372z"
      />
      <path
        fill="#FFF"
        d="M15.912 14.62c0 3.477-2.88 5.748-6.48 5.748H1.08V.71h7.848c3.456 0 6.336 2.2 6.336 5.606a4.852 4.852 0 0 1-1.728 3.832 5.146 5.146 0 0 1 2.376 4.471zM5.616 4.896v3.477h3.24c.475.013.934-.172 1.263-.51.329-.338.498-.797.465-1.264a1.682 1.682 0 0 0-.478-1.253 1.733 1.733 0 0 0-1.25-.521h-3.24v.07zm5.76 9.368a1.795 1.795 0 0 0-.51-1.363 1.85 1.85 0 0 0-1.362-.554H5.616v3.762h3.816a1.773 1.773 0 0 0 1.41-.474c.376-.357.571-.859.534-1.371z"
      />
      <path
        fill="#FFF"
        d="M16.992 15.258c0 3.477-2.88 5.748-6.48 5.748H2.16V1.348h7.848c3.456 0 6.336 2.2 6.336 5.607a4.852 4.852 0 0 1-1.728 3.832 5.238 5.238 0 0 1 2.376 4.471zM6.696 5.535v3.478h3.24c.475.013.934-.172 1.263-.51.329-.338.498-.797.465-1.264a1.682 1.682 0 0 0-.478-1.253 1.733 1.733 0 0 0-1.25-.521c0 .07-3.24.07-3.24.07zm5.76 9.368a1.795 1.795 0 0 0-.51-1.362 1.85 1.85 0 0 0-1.362-.554H6.696v3.761h3.816a1.773 1.773 0 0 0 1.41-.474c.376-.356.571-.858.534-1.37z"
      />
      <path
        fill="#FFF"
        d="M15.552 14.406c0 3.478-2.88 5.749-6.48 5.749H.72V.497h7.848c3.456 0 6.336 2.2 6.336 5.606a4.852 4.852 0 0 1-1.728 3.832 5.146 5.146 0 0 1 2.376 4.471zM5.256 4.684V8.16h3.24c.475.013.934-.172 1.263-.51.329-.338.498-.797.465-1.264a1.682 1.682 0 0 0-.478-1.253 1.733 1.733 0 0 0-1.25-.521h-3.24v.07zm5.76 9.368a1.795 1.795 0 0 0-.51-1.363 1.85 1.85 0 0 0-1.362-.554H5.256v3.762h3.816a1.773 1.773 0 0 0 1.41-.474c.376-.357.571-.859.534-1.371z"
      />
      <path
        fill="#FFF"
        d="M15.192 14.194c0 3.477-2.88 5.748-6.48 5.748H.36V.284h7.848c3.456 0 6.336 2.2 6.336 5.606a4.852 4.852 0 0 1-1.728 3.833 5.146 5.146 0 0 1 2.376 4.47zM4.896 4.47v3.477h3.24c.475.014.934-.172 1.263-.51.329-.337.498-.797.465-1.264a1.682 1.682 0 0 0-.478-1.253 1.733 1.733 0 0 0-1.25-.521l-3.24.071zm5.76 9.368a1.795 1.795 0 0 0-.51-1.362 1.85 1.85 0 0 0-1.362-.554H4.896v3.76h3.816a1.744 1.744 0 0 0 1.422-.461 1.69 1.69 0 0 0 .522-1.383z"
      />
      <path
        fill="#FFF"
        d="M14.832 13.98c0 3.478-2.88 5.749-6.48 5.749H0V0h7.848c3.456 0 6.336 2.2 6.336 5.606a4.852 4.852 0 0 1-1.728 3.833 5.364 5.364 0 0 1 2.376 4.542zM4.608 4.259v3.477h3.24c.475.014.934-.172 1.263-.51.329-.337.498-.796.465-1.264a1.682 1.682 0 0 0-.478-1.252 1.733 1.733 0 0 0-1.25-.522c0 .071-3.24.071-3.24.071zm5.688 9.368a1.795 1.795 0 0 0-.51-1.362 1.85 1.85 0 0 0-1.362-.554H4.608v3.761h3.816a1.721 1.721 0 0 0 1.381-.484c.363-.358.544-.858.491-1.361z"
      />
      <path
        fill="#FFF"
        d="M0 19.729l2.304 1.632.216-.71-2.304-1.49-.216-.07z"
      />
      {/* avoid this type of code, this is the type of component that we should discuss if it is in the right place. */}
      <path
        fill={color || '#000'}
        d="M0 13.058l2.52 1.632v-.78L.216 12.49 0 12.348zM0 11.78l2.52 1.491v-2.555L.216 9.296 0 9.156zM2.664 1.49L0 0h1.08l2.88 1.49zM17.352 15.4c0 3.477-2.88 5.748-6.48 5.748H2.52V1.49h7.848c3.456 0 6.336 2.2 6.336 5.607a4.852 4.852 0 0 1-1.728 3.832 5.315 5.315 0 0 1 2.376 4.471zM7.056 5.677v3.478h3.24c.475.013.934-.172 1.263-.51.329-.338.498-.797.465-1.264a1.647 1.647 0 0 0-.465-1.265 1.697 1.697 0 0 0-1.263-.51l-3.24.071zm5.688 9.368a1.795 1.795 0 0 0-.51-1.362 1.85 1.85 0 0 0-1.362-.554H7.056v3.761h3.816a1.721 1.721 0 0 0 1.381-.483c.363-.358.544-.858.491-1.362z"
      />
      <path
        fill="#FCFAFA"
        d="M10.872 21.361H2.304V1.277h7.992c3.744 0 6.48 2.484 6.48 5.82a5.11 5.11 0 0 1-1.656 3.832 5.278 5.278 0 0 1 2.304 4.471c.144 3.477-2.664 5.961-6.552 5.961zm-8.208-.355h8.208c3.6 0 6.264-2.341 6.264-5.606a4.81 4.81 0 0 0-2.376-4.258L14.544 11l.216-.142a4.704 4.704 0 0 0 1.656-3.69c0-3.123-2.592-5.465-6.12-5.465H2.664v19.303zm8.208-3.832H6.84v-4.187h4.032a2.138 2.138 0 0 1 1.767 2.094 2.138 2.138 0 0 1-1.767 2.093zm-3.6-.426h3.6c.457.016.898-.161 1.214-.486a1.57 1.57 0 0 0 .442-1.217 1.57 1.57 0 0 0-.442-1.216 1.618 1.618 0 0 0-1.214-.487h-3.6v3.406zm3.024-7.38H6.84V5.465h3.456a1.865 1.865 0 0 1 1.405.531c.37.366.566.869.539 1.385a1.83 1.83 0 0 1-.519 1.424c-.371.38-.89.585-1.425.563zm-3.024-.355h3.096c.42.023.83-.137 1.121-.437.29-.3.434-.711.391-1.124a1.464 1.464 0 0 0-.412-1.105 1.508 1.508 0 0 0-1.1-.457H7.272v3.123z"
      />
    </g>
  </svg>
);

Icon.displayName = 'Icon.Bonde';

export default Icon;
