import React from "react";

const Icon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#000"
        d="M32.836.07H3.164A3.168 3.168 0 000 3.234v25.313a3.168 3.168 0 003.164 3.164h8.167A7.392 7.392 0 0018 35.93a7.392 7.392 0 006.67-4.22h8.166A3.168 3.168 0 0036 28.548V3.234A3.168 3.168 0 0032.836.07zM3.164 2.18h29.672c.581 0 1.055.473 1.055 1.054v5.274H2.109V3.234c0-.581.474-1.054 1.055-1.054zM18 33.82a5.28 5.28 0 01-5.273-5.273A5.28 5.28 0 0118 23.273a5.28 5.28 0 015.273 5.274A5.28 5.28 0 0118 33.82zm14.836-4.218h-7.529c.639-4.447-2.817-8.438-7.307-8.438-4.477 0-7.948 3.977-7.307 8.438H3.164a1.056 1.056 0 01-1.055-1.055v-17.93h31.782v17.93c0 .581-.474 1.055-1.055 1.055z"
      ></path>
      <path
        fill="#000"
        d="M11.93 12.758a1.055 1.055 0 00-1.28.767l-.476 1.906-.786-1.18a1.055 1.055 0 00-1.756 0l-.786 1.18-.477-1.906a1.054 1.054 0 10-2.046.512l1.054 4.219a1.055 1.055 0 001.901.329l1.232-1.848 1.232 1.848a1.055 1.055 0 001.9-.33l1.055-4.218a1.055 1.055 0 00-.767-1.279zM21.42 12.758a1.055 1.055 0 00-1.279.767l-.476 1.906-.787-1.18a1.055 1.055 0 00-1.755 0l-.787 1.18-.477-1.906a1.054 1.054 0 10-2.046.512l1.055 4.219a1.055 1.055 0 001.9.329L18 16.737l1.232 1.848a1.055 1.055 0 001.9-.33l1.056-4.218a1.055 1.055 0 00-.768-1.279zM30.914 12.758a1.055 1.055 0 00-1.279.767l-.476 1.906-.787-1.18a1.055 1.055 0 00-1.755 0l-.787 1.18-.476-1.906a1.054 1.054 0 10-2.047.512l1.055 4.219a1.055 1.055 0 001.9.329l1.232-1.848 1.232 1.848a1.055 1.055 0 001.901-.33l1.055-4.218a1.054 1.054 0 00-.768-1.279zM19.785 26.746l-2.629 2.63-1.012-1.013a1.055 1.055 0 00-1.491 1.492l1.757 1.758c.412.412 1.08.412 1.492 0l3.375-3.375a1.055 1.055 0 10-1.492-1.492zM5.344 6.398a1.055 1.055 0 100-2.109 1.055 1.055 0 000 2.11zM9.563 6.398a1.055 1.055 0 100-2.109 1.055 1.055 0 000 2.11zM13.781 6.398a1.055 1.055 0 100-2.109 1.055 1.055 0 000 2.11zM24.328 6.398h6.328a1.055 1.055 0 000-2.109h-6.328a1.055 1.055 0 000 2.11z"
      ></path>
    </svg>
  );
}

Icon.displayName = 'ActiveDomainIcon';

export default Icon;
