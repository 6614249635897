export default {
  transparent: 'transparent',
  black: '#000000',
  white: '#FFFFFF',
  red: {
    50: '#FBCDCD',
    100: '#FF8C8C',
    200: '#FF2B4E',
    300: '#D92E4A',
    400: '#B42940',
  },
  gray: {
    50: '#EEEEEE',
    100: '#D1CDD2',
    200: '#AAAAAA',
    300: '#9B9B9B',
    400: '#424242',
  },
  green: {
    50: '#BBF1E5',
    100: '#9AE3D3',
    200: '#50E3C2',
    300: '#5ACAB1',
    400: '#4A9A88',
  },
  pink: {
    50: '#F9C2E5',
    100: '#F996C5',
    200: '#EE0099',
    300: '#E2058A',
    400: '#B4006C',
  },
  blue: {
    50: '#BFDDF6',
    100: '#8DC4F4',
    200: '#1E88E5',
    300: '#0D76D1',
    400: '#005EB0',
  },
  yellow: {
    50: '#FFE5BF',
    100: '#FFD18C',
    200: '#FFCF23',
    300: '#FFCF23',
    400: '#DDAD03',
  },
};
