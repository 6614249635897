'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var hasEmbed = exports.hasEmbed = function hasEmbed(value) {
  return value.inlines.some(function (inline) {
    return inline.type === 'embed';
  });
};

var appendEmbed = exports.appendEmbed = function appendEmbed(change, embed) {
  return change.insertInline({
    type: 'embed',
    isVoid: true,
    data: { embed: embed }
  });
};