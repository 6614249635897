'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require('@slate-editor/utils');

var _ItalicUtils = require('./ItalicUtils');

var ItalicKeyboardShortcut = function ItalicKeyboardShortcut(event, change) {
  if (_utils.keyboardEvent.isMod(event) && !event.shiftKey && event.key === 'i') {
    return (0, _ItalicUtils.italicMarkStrategy)(change);
  }
  return;
};

exports.default = ItalicKeyboardShortcut;